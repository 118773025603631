<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <PageHeader title="Engineers" backTo="internal"/>
    <main
        class="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
    >

      <!-- Job Sheets -->
      <router-link
          to="/internal/service/job-sheet/view"
          class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
            class="h-8 w-8 mr-5 stroke-current"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
          <path stroke="none" d="M0 0h24v24H0z"/>
          <path d="M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2"/>
          <path d="M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v0a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2zM9 12h6M9 16h6"/>
        </svg>

        <h2 class="text-lg uppercase">Job Sheets</h2>
      </router-link>

      <!-- Scheduling -->
      <router-link
          to="/internal/service/schedule/view"
          class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
            class="h-8 w-8 mr-5 stroke-current"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
          <path stroke="none" d="M0 0h24v24H0z"/>
          <path d="M11.795 21H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4"/>
          <path d="M14 18a4 4 0 1 0 8 0 4 4 0 1 0-8 0M15 3v4M7 3v4M3 11h16"/>
          <path d="M18 16.496V18l1 1"/>
        </svg>

        <h2 class="text-lg uppercase">Scheduling</h2>
      </router-link>


      <!-- My Schedules -->
      <router-link
          to="/internal/service/my-schedule/view"
          class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
            class="h-8 w-8 mr-5 stroke-current"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
          <path stroke="none" d="M0 0h24v24H0z"/>
          <path d="M4 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zM16 3v4M8 3v4M4 11h16"/>
          <path d="M8 15h2v2H8z"/>
        </svg>

        <h2 class="text-lg uppercase">Schedules</h2>
      </router-link>

      <!-- Certificate -->
      <router-link
          to="/internal/service/certificate/view"
          class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex items-center relative"
      >
        <svg
            class="h-8 w-8 mr-5 stroke-current"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            viewBox="0 0 24 24"
        >
          >
          <path stroke="none" d="M0 0h24v24H0z"/>
          <path d="M12 15a3 3 0 1 0 6 0 3 3 0 1 0-6 0"/>
          <path d="M13 17.5V22l2-1.5 2 1.5v-4.5"/>
          <path d="M10 19H5a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-1 1.73M6 9h12M6 12h3M6 15h2"/>
        </svg>

        <h2 class="text-lg uppercase">Certificate</h2>
      </router-link>
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");

export default {
  name: "HR",
  components: {
    PageHeader,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
